.enlis-dashboard .ant-radio-button-wrapper {
  background: transparent !important;
  font-size: 18px !important;
}
.enlis-dashboard .ant-select-selector {
  flex-direction: row;
  width: 200px !important;
  font-size: 18px !important;
  border: 1px solid #765F32 !important;
  border-radius: 6px !important;
}
.enlis-dashboard .gx-line-indicator-col {
  width: 50% !important;
}
.enlis-dashboard .gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #4CA539 !important;
  color: #ffffff !important;
}
.enlis-dashboard .ant-statistic-title {
  color: #0b0b0b !important;
}
.gx-line-indicator-col {
  width: 50% !important;
  padding-right: 10px;
}
.gx-overview-description {
  width: 50% !important;
}
.gx-dealclose-header {
  width: 100% !important;
}
.dashboard_title {
  font-size: 22px;
}
.gx-overview-description {
  color: #0b0b0b !important;
}
#mapCharts {
  height: 100%;
  width: 100%;
  position: absolute;
}
.ant-radio-button-wrapper {
  background: #FAFAFF;
}
.ant-card {
  background: #fafaff;
}
.gx-header-horizontal-top {
  font-size: 18px !important;
}
.ant-menu {
  font-size: 18px !important;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px !important;
}
.ant-card {
  font-size: 18px !important;
}
h5,
.h5 {
  font-size: 18px !important;
}
h4,
.h4 {
  font-size: 18px !important;
  color: #fa8c15 !important;
}
.dark-theme .ant-card {
  color: #FFFFFF !important;
}
.dark-theme {
  color: #FFFFFF !important;
}
.dark-theme .ant-radio-button-wrapper {
  color: #FFFFFF !important;
  font-size: 18px !important;
}
.dark-theme .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15 !important;
}
.ant-radio-button-wrapper {
  background-color: transparent !important;
  color: #fff !important;
}
.ant-radio-button-wrapper-checked {
  background-color: #fa8c15 !important;
}
.gx-card-ticketlist .gx-task-item-content-left {
  width: calc(100% - 10px) !important;
}
body {
  color: #545454;
  font-size: 16px;
}
.gx-bg-white {
  background-color: #fafaff !important;
}
.ant-card-body {
  padding: 5px;
}
.App {
  font-family: "Noir Pro", sans-serif;
  text-align: center;
}
.App iframe {
  height: 700px;
  width: 100%;
  border: none;
}
